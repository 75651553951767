import {
  UncontrolledTooltip,
  Col,
  Row,
  Tab,
  Tabs,
  NavItem,
  NavLink,
  Card,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import React, { useState, useEffect, useContext } from "react";

import { API_URL } from "variables/general";
import { Line } from "react-chartjs-2";
import ReactSelect from "react-select";
import {
  configurationDataContext,
  bmsIdContext,
  valueDataContext,
  recordsContext
} from "./Dashboard";
let someArray = []
function Analytics(props) {
  useEffect(() => {
    fetchBmsValues();
    // fetchChargerValues();
    getBmsConfiguration()
  }, []);
  const id = useContext(bmsIdContext);
  const records = useContext(recordsContext);
  const [configurationData, setConfigurationData] = useState("");
  const [valueData, setValueData] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [bmsValues, setBmsValues] = useState([]);
  // const [chargerValueData, setChargerValueData] = useState("");

  const [tootltipArray, setTooltipArray] = useState();
  const [noOfSamples, setNoOfSamples] = useState(20);
  const [onClickRange, setOnClickRange] = useState(2);
  const [onClickTempRange, setOnClickTempRange] = useState(2);
  const [loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [iconPills, setIconPills] = useState("1");
  const [clickLoader, setClickLoader] = useState(false);
  let date = records[records.length - 1]
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  if (month < 10)
    month = '0' + month
  let day = date.getDate()
  if (day < 10)
    day = '0' + day
  const [sTms, setSTms] = useState(`${year}-${month}-${day}T00:00:00Z`);
  const [time, setTime] = useState('');
  const getBmsConfiguration = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        batteryPackId: id
      }),
      redirect: "follow",
    };
    fetch(API_URL + "/bms/settings_fetch", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setConfigurationData(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  const fetchBmsValues = () => {
    setLoader(true)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        batteryPackId: id,
        tms: sTms
      }),
      redirect: "follow",
    };
    fetch(API_URL + "/bms/v2/bms_values", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let bmsValuesArray = result.data.bmsValues
        setLoader(false)
        if (bmsValuesArray.length > 0) {
          bmsValuesArray.sort((a, b) => new Date(a.tms) - new Date(b.tms));
          setBmsValues(bmsValuesArray)
          setStartIndex(bmsValuesArray.length - 1 - noOfSamples)
          setEndIndex(bmsValuesArray.length - 1)
          settingDisplayValues(bmsValuesArray, bmsValuesArray.length - 1 - noOfSamples, bmsValuesArray.length - 1)
        }
        else alert('No Data Found')
      })
      .catch((error) => console.log("error", error));
  };
  const settingDisplayValues = (bmsValues, startIndex, endIndex) => {
    let subArray = bmsValues.slice(startIndex, endIndex + 1)
    setValueData(subArray);
    setClickLoader(false);
  }
  // const fetchChargerValues = (val, lt) => {
  //   let myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append(
  //     "Authorization",
  //     "Bearer " + window.localStorage.getItem("token")
  //   );
  //   let requestOptions;
  //   if (lt === "LT") {
  //     if (val) {
  //       requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: JSON.stringify({
  //           batteryPackId: id,
  //           limit: noOfSamples,
  //           tms_lt: val,
  //         }),
  //       };
  //     }
  //   } else {
  //     if (val) {
  //       requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: JSON.stringify({
  //           batteryPackId: id,
  //           limit: noOfSamples,
  //           tms: val,
  //         }),
  //       };
  //     } else
  //       requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: JSON.stringify({
  //           batteryPackId: id,
  //           limit: noOfSamples,
  //         }),
  //         redirect: "follow",
  //       };
  //   }
  //   fetch(API_URL + "/bms/charger_data", requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       setChargerValueData(result.data);
  //       setClickLoader(false);
  //     })
  //     .catch((error) => console.log("error", error));
  // };
  const getDateTime = (event) => {
    let dateArray = []
    records.forEach(element => {
      let year = element.getFullYear()
      let month = element.getMonth() + 1
      if (month < 10)
        month = '0' + month
      let day = element.getDate()
      if (day < 10)
        day = '0' + day
      dateArray.push(`${year}-${month}-${day}`)
    });
    if (dateArray.includes(event)) {
      setSTms(event + 'T00:00:00Z')
    }
    else alert('No Data for this date')
  };
  const getTime = (event) => {
    let searchTime = sTms.split("T")[0] + 'T' + event + ':00Z'

    let timestampObj = bmsValues.findIndex(obj => {
      let objTimestamp = new Date(obj.tms).getTime();
      return objTimestamp >= new Date(searchTime).getTime();
    });
    let foundTimeIndex = timestampObj
    let updatedStartIndex = foundTimeIndex
    let updatedEndIndex = foundTimeIndex + noOfSamples
    if (updatedEndIndex > bmsValues.length)
      updatedEndIndex = bmsValues.length
    setStartIndex(updatedStartIndex)
    setEndIndex(updatedEndIndex)
    let subArray = bmsValues.slice(updatedStartIndex, updatedEndIndex + 1)
    setValueData(subArray);
    setClickLoader(false);
  };
  const incrementFunction = () => {
    if (onClickRange < 4 && onClickRange >= 0)
      setOnClickRange(onClickRange + 1);
  };
  const decrementFunction = () => {
    if (onClickRange > 0) setOnClickRange(onClickRange - 1);
  };
  const incrementTempFunction = () => {
    if (onClickTempRange < 4 && onClickTempRange >= 0)
      setOnClickTempRange(onClickTempRange + 1);
  };
  const decrementTempFunction = () => {
    if (onClickTempRange > 0) setOnClickTempRange(onClickTempRange - 1);
  };
  const previousClicked = (val) => {
    setClickLoader(true);
    let updatedStartIndex = startIndex - noOfSamples
    let updatedEndIndex = endIndex - noOfSamples
    if (updatedStartIndex < 0) {
      updatedStartIndex = 0
      updatedEndIndex = noOfSamples
      setStartIndex(updatedStartIndex)
      setEndIndex(updatedEndIndex)
      let subArray = bmsValues.slice(updatedStartIndex, updatedEndIndex)
      setValueData(subArray);
    }
    else {
      setStartIndex(updatedStartIndex)
      setEndIndex(updatedEndIndex)
      let subArray = bmsValues.slice(updatedStartIndex, updatedEndIndex)
      setValueData(subArray);
    }
    setClickLoader(false);
  };
  const nextClicked = (val) => {
    setClickLoader(true);
    let updatedStartIndex = startIndex + noOfSamples
    let updatedEndIndex = endIndex + noOfSamples
    if (updatedEndIndex > bmsValues.length) {
    }
    else {
      setStartIndex(updatedStartIndex)
      setEndIndex(updatedEndIndex)
      let subArray = bmsValues.slice(updatedStartIndex, updatedEndIndex)
      setValueData(subArray);
    }
    setClickLoader(false);
  };
  const recenter = () => {
    fetchBmsValues();
  };
  const changeNoOfSample = (val) => {
    let updatedStartIndex = endIndex - noOfSamples
    let updatedEndIndex = endIndex
    if (updatedStartIndex < 0) {
      updatedStartIndex = 0
      setStartIndex(updatedStartIndex)
      let subArray = bmsValues.slice(updatedStartIndex, updatedEndIndex)
      setValueData(subArray);
    }
    else {
      setStartIndex(updatedStartIndex)
      setEndIndex(updatedEndIndex)
      let subArray = bmsValues.slice(updatedStartIndex, updatedEndIndex)
      setValueData(subArray);
    }
  };
  const checkBoxData = (event) => {
    setFilterData(event);
  };
  const cardStyle = {
    padding: "5px",
    margin: "5px",
  };
  const buttonStyle = {
    borderRadius: "5px",
    margin: "10px",
    padding: "5px 10px",
    color: "white",
    backgroundColor: "#878787",
    borderWidth: 0,
  };
  const XbuttonStyle = {
    borderRadius: "15px",
    borderWidth: 0,

    padding: "5px",
    color: "grey",
    backgroundColor: "white",
    float: "right",
  };
  if (!loader) 
  {if (valueData.length > 0) {
    let overChargeCurrent = [];
    let overDisCurrent = [];
    let hardChgOverCurrent = [];
    let hardDsgOverCurrent = [];

    let packOverVoltage = [];
    let packUnderVoltage = [];
    let hardPackOverVoltage = []; 
    let hardPackUnderVoltage = [];

    let chgOverTemp = [];
    let chgUnderTemp = [];
    let disOverTemp = [];
    let disLowTemp = [];
    let tempHardwareReserve = 90;
    let tempSoftLimit = 85;

    let reserveCapacityS1 = [];
    let reserveCapacityS2 = [];

    let cellUnderVoltage = [];
    let cellOverVoltage = [];
    let hardCellUnderVoltage = [];
    let hardCellOverVoltage = [];
    let chgUTRelease = [];
    let dsgOTRelease = [];
    let hardLimitTemp = [];
    if (configurationData) {
      for (let i = 0; i < valueData.length; i++) {
        overChargeCurrent[i] = configurationData.overChargeCurrent / 1000;
        overDisCurrent[i] = -configurationData.overDisCurrent / 1000;
        hardChgOverCurrent[i] = configurationData.hardChgOverCurrent / 1000;
        hardDsgOverCurrent[i] = -configurationData.hardDsgOverCurrent / 1000;

        packOverVoltage[i] = configurationData.packOverVoltage / 1000;
        packUnderVoltage[i] = configurationData.packUnderVoltage / 1000;
        hardPackOverVoltage[i] = configurationData.hardPackOverVoltage / 1000;
        hardPackUnderVoltage[i] = configurationData.hardPackUnderVoltage / 1000;

        chgOverTemp[i] = configurationData.chgOverTemp;
        chgUnderTemp[i] = configurationData.chgUnderTemp;
        chgUTRelease[i] = configurationData.chgUTRelease;
        disOverTemp[i] = configurationData.disOverTemp;
        disLowTemp[i] = configurationData.disLowTemp;
        dsgOTRelease[i] = configurationData.dsgOTRelease;
        hardLimitTemp[i] = 90;

        reserveCapacityS1[i] = configurationData.reserveCapacityS1;
        reserveCapacityS2[i] = configurationData.reserveCapacityS2;

        cellUnderVoltage[i] = configurationData.cellUnderVoltage;
        cellOverVoltage[i] = configurationData.cellOverVoltage;
        hardCellUnderVoltage[i] = configurationData.hardCellUnderVoltage;
        hardCellOverVoltage[i] = configurationData.hardCellOverVoltage;
      }
    }
    //tms
    let timeLabel = [];
    let prevDate;
    if (valueData.length > 1) {
      prevDate = valueData[1].tms.slice(0, 10);
      timeLabel[0] =
        valueData[0].tms.slice(8, 10) +
        "/" +
        valueData[0].tms.slice(5, 7) +
        " " +
        valueData[0].tms.slice(11, 16);
      for (let i = 1; i < valueData.length; i++) {
        if (prevDate === valueData[i].tms.slice(0, 10))
          timeLabel[i] = valueData[i].tms.slice(11, 16);
        else {
          timeLabel[i] =
            valueData[i].tms.slice(8, 10) +
            "/" +
            valueData[i].tms.slice(5, 7) +
            " " +
            valueData[i].tms.slice(11, 16);
          prevDate = valueData[i].tms.slice(0, 10);
        }
      }
    }
    let cellVoltageArray = [[]];
    let cellDiff = [];
    for (let i = 0; i < valueData.length; i++) {
      cellVoltageArray[i] = JSON.parse(valueData[i].cellVoltage);
      cellDiff[i] =
        Math.max(...JSON.parse(valueData[i].cellVoltage)) -
        Math.min(...JSON.parse(valueData[i].cellVoltage));
    }
    let cellTempArray = [[]];
    for (let i = 0; i < valueData.length; i++) {
      cellTempArray[i] = JSON.parse(valueData[i].cellTemp);
    }
    let icTempArray = [[]];
    for (let i = 0; i < valueData.length; i++) {
      icTempArray[i] = JSON.parse(valueData[i].icTemp);
    }
    let pcbTempArray = [[]];
    for (let i = 0; i < valueData.length; i++) {
      pcbTempArray[i] = JSON.parse(valueData[i].pcbTemp);
    }

    //Temp-ChargeDischarge
    let HeatSinkTempData = [];
    // let HeatSinkTempLabel = [];
    for (let i = 0; i < valueData.length; i++)
      HeatSinkTempData[i] = valueData[i].heatsink[0];

    //PackVoltage
    let PackVoltageData = [];
    let PackCurrentData = [];
    let AveragePackCurrentData = [];
    let MaxPackCurrentData = [];
    let SOHData = [];
    let StateOfChargeData = [];
    let PackPowerData = [];
    let PDUTempMax = [];
    let CellTempMax = [];
    for (let i = 0; i < valueData.length; i++) {
      PackVoltageData[i] = valueData[i].packVoltage / 1000;
      PackCurrentData[i] = valueData[i].packCurrent / 1000;
      SOHData[i] = valueData[i].soH;
      StateOfChargeData[i] = valueData[i].soC;
      PackPowerData[i] = valueData[i].packPower / 1000;
      AveragePackCurrentData[i] = valueData[i].avgPackCurrent / 1000;
      MaxPackCurrentData[i] = valueData[i].maxPackCurrent / 1000;
      PDUTempMax[i] = Math.max(...JSON.parse(valueData[i].pcbTemp));
      CellTempMax[i] = Math.max(...JSON.parse(valueData[i].cellTemp));
    }
    // Charger data manipulation---------------------->
    // let chargerTms = [];
    // let ChargerVoltageData = [];
    // let chargerCurrentData = [];
    // let chargerTemperatureData = [[]];
    // if (chargerValueData)
    //   if (chargerValueData.bmsChargerValues.length > 0) {
    //     // console.log('<---------------------------chargerValueData------------------------>',chargerValueData)
    //     for (let index = 0; index < chargerValueData.bmsChargerValues.length; index++) {
    //       chargerTms[index] = chargerValueData.bmsChargerValues[index].tms.slice(8, 10) +
    //         "/" +
    //         chargerValueData.bmsChargerValues[index].tms.slice(5, 7) +
    //         " " +
    //         chargerValueData.bmsChargerValues[index].tms.slice(11, 16);
    //       ChargerVoltageData[index] = chargerValueData.bmsChargerValues[index].chargerVoltage / 1000
    //       chargerCurrentData[index] = chargerValueData.bmsChargerValues[index].chargerCurrent / 1000
    //       chargerTemperatureData[index] = JSON.parse(chargerValueData.bmsChargerValues[index].chargerTemp)
    //     }
    //   }

    let cellVoltageDataSets = [];
    let max_len = 0;
    for (let l_max_len = 0; l_max_len < cellVoltageArray.length; l_max_len++) {
      if (cellVoltageArray[l_max_len].length > max_len) {
        max_len = cellVoltageArray[l_max_len].length;
      }
    }
    let cellVotageNewArray = Array(max_len)
      .fill(0)
      .map((row) => new Array(cellVoltageArray.length).fill(0));

    for (let k = 0; k < cellVoltageArray.length; k++) {
      for (let j = 0; j < cellVoltageArray[k].length; j++) {
        cellVotageNewArray[j][k] = cellVoltageArray[k][j];
      }
    }
    function generateDistinctColors(count) {
      const colors = [];
      const saturation = 65; // Keeps colors vibrant
      const lightness = 55;  // Keeps colors bright but readable
      
      for (let i = 0; i < count; i++) {
        const hue = (i * (360 / (count/10))) % 360;
        colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
      }
    
      return colors;
    }
    
    const colorArray = generateDistinctColors(cellVotageNewArray.length);
    for (let x = 0; x < cellVotageNewArray.length; x++) {
      // let colorArray = [
      //   "#5D8AA8",
      //   "#FFBF00",
      //   "#E32636",
      //   "#A4C639",
      //   "#FBCEB1",
      //   "#7FFFD4",
      //   "#4B5320",
      //   "#3B444B",
      //   "#E9D66B",
      //   "#6D351A",
      //   "#007FFF",
      //   "#0000FF",
      //   "#DE5D83",
      //   "#CC0000",
      //   "#66FF00",
      //   "#C32148",
      //   "#FF007F",
      //   "#08E8DE",
      //   "#004225",
      //   "#800020",
      //   "#FF0800",
      //   "#FF3800",
      //   "#8B008B",
      //   "#03C03C",
      //   "#C04000",
      //   "#191970",
      //   "#77DD77",
      // ];
      cellVoltageDataSets[x] = {
        label: "Cell " + (x + 1),
        data: cellVotageNewArray[x],
        fill: false,
        borderColor: colorArray[x],
        backgroundColor: "transparent",
        pointBorderColor: colorArray[x],
        pointRadius: 1,
        pointHoverRadius: 10,
        pointBorderWidth: 0,
      };
    }
    cellVoltageDataSets[cellVotageNewArray.length] = {
      label: "Cell Over Voltage(mV)",
      data: cellOverVoltage,
      fill: false,
      borderColor: "#c9d4cc",
      backgroundColor: "transparent",
      pointBorderColor: "#c9d4cc",
      pointRadius: 1,
      pointHoverRadius: 10,
      pointBorderWidth: 0,
      borderDash: [1, 1],
    };
    cellVoltageDataSets[cellVotageNewArray.length + 1] = {
      label: "Cell Under Voltage(mV)",
      data: cellUnderVoltage,
      fill: false,
      borderColor: "#c9d4cc",
      backgroundColor: "transparent",
      pointBorderColor: "#c9d4cc",
      pointRadius: 1,
      pointHoverRadius: 10,
      pointBorderWidth: 0,
      borderDash: [1, 1],
    };
    cellVoltageDataSets[cellVotageNewArray.length + 2] = {
      label: "Hard Cell Over Voltage(mV)",
      data: hardCellOverVoltage,
      fill: false,
      borderColor: "#878a88",
      backgroundColor: "transparent",
      pointBorderColor: "#878a88",
      pointRadius: 1,
      pointHoverRadius: 10,
      pointBorderWidth: 0,
      borderDash: [1, 1],
    };
    cellVoltageDataSets[cellVotageNewArray.length + 3] = {
      label: "Hard Cell Under Voltage(mV)",
      data: hardCellUnderVoltage,
      fill: false,
      borderColor: "#878a88",
      backgroundColor: "transparent",
      pointBorderColor: "#878a88",
      pointRadius: 1,
      pointHoverRadius: 10,
      pointBorderWidth: 0,
      borderDash: [1, 1],
    };
    cellVoltageDataSets[cellVotageNewArray.length + 4] = {
      label: "Cell Voltage Difference(mV)",
      data: cellDiff,
      fill: false,
      borderColor: "transparent",
      backgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointRadius: 1,
      pointHoverRadius: 10,
      pointBorderWidth: 0,
      borderDash: [1, 1],
    };
    cellVoltageDataSets[cellVotageNewArray.length + 5] = {
      label: "Pack Current(A)",
      data: PackCurrentData,
      fill: false,
      borderColor: "transparent",
      backgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointRadius: 1,
      pointHoverRadius: 10,
      pointBorderWidth: 0,
      borderDash: [1, 1],
    };
    cellVoltageDataSets[cellVotageNewArray.length + 6] = {
      label: "PDU Temperature[max](°C)",
      data: PDUTempMax,
      fill: false,
      borderColor: "transparent",
      backgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointRadius: 1,
      pointHoverRadius: 10,
      pointBorderWidth: 0,
      borderDash: [1, 1],
    };
    cellVoltageDataSets[cellVotageNewArray.length + 7] = {
      label: "Cell Temperature[max](°C)",
      data: CellTempMax,
      fill: false,
      borderColor: "transparent",
      backgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointRadius: 1,
      pointHoverRadius: 10,
      pointBorderWidth: 0,
      borderDash: [1, 1],
    };
    cellVoltageDataSets[cellVotageNewArray.length + 8] = {
      label: "SOC(%)",
      data: StateOfChargeData,
      fill: false,
      borderColor: "transparent",
      backgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointRadius: 1,
      pointHoverRadius: 10,
      pointBorderWidth: 0,
      borderDash: [1, 1],
    };
    cellVoltageDataSets[cellVotageNewArray.length + 9] = {
      label: "Pack Voltage(V)",
      data: PackVoltageData,
      fill: false,
      borderColor: "transparent",
      backgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointRadius: 1,
      pointHoverRadius: 10,
      pointBorderWidth: 0,
      borderDash: [1, 1],
    };
    let cellTempDataSets = [];
    let temp_max_len = 0;
    for (let te_l_len = 0; te_l_len < cellTempArray.length; te_l_len++) {
      if (cellTempArray[te_l_len].length > temp_max_len) {
        temp_max_len = cellTempArray[te_l_len].length;
      }
    }
    let pcb_temp_max_len = 0;
    for (let te_l_len = 0; te_l_len < pcbTempArray.length; te_l_len++) {
      if (pcbTempArray[te_l_len].length > pcb_temp_max_len) {
        pcb_temp_max_len = pcbTempArray[te_l_len].length;
      }
    }
    // let chargerTemp_temp_max_len = 0;
    // for (let te_l_len = 0; te_l_len < chargerTemperatureData.length; te_l_len++) {
    //   if (chargerTemperatureData[te_l_len].length > chargerTemp_temp_max_len) {
    //     chargerTemp_temp_max_len = chargerTemperatureData[te_l_len].length;
    //   }
    // }
    let ic_temp_max_len = 0;
    for (let te_l_len = 0; te_l_len < icTempArray.length; te_l_len++) {
      if (icTempArray[te_l_len].length > ic_temp_max_len) {
        ic_temp_max_len = icTempArray[te_l_len].length;
      }
    }
    let cellTempNewArray = Array(temp_max_len)
      .fill(0)
      .map((row) => new Array(cellTempArray.length).fill(0));

    for (let k = 0; k < cellTempArray.length; k++) {
      for (let j = 0; j < cellTempArray[k].length; j++) {
        cellTempNewArray[j][k] = cellTempArray[k][j];
      }
    }

    for (let x = 0; x < cellTempNewArray.length; x++) {
      let colorArray = [
        "#5D8AA8",
        "#FFBF00",
        "#E32636",
        "#A4C639",
        "#FBCEB1",
        "#7FFFD4",
        "#4B5320",
        "#3B444B",
      ];
      cellTempDataSets[x] = {
        label: "Cell Temp " + (x + 1) + " (°C)",
        data: cellTempNewArray[x],
        fill: false,
        borderColor: colorArray[x],
        backgroundColor: "transparent",
        pointBorderColor: colorArray[x],
        pointRadius: 1,
        pointHoverRadius: 10,
        pointBorderWidth: 0,
      };
    }
    let icTempDataSets = [];
    let icTempNewArray = Array(ic_temp_max_len)
      .fill(0)
      .map((row) => new Array(icTempArray.length).fill(0));

    for (let k = 0; k < icTempArray.length; k++) {
      for (let j = 0; j < icTempArray[k].length; j++) {
        icTempNewArray[j][k] = icTempArray[k][j];
      }
    }

    for (let x = 0; x < icTempNewArray.length; x++) {
      let colorArray = ["#E9D66B", "#6D351A", "#007FFF", "#0000FF", "#66FF00"];
      icTempDataSets[x] = {
        label: "IC Temp " + (x + 1) + " (°C)",
        data: icTempNewArray[x],
        fill: false,
        borderColor: colorArray[x],
        backgroundColor: "transparent",
        pointBorderColor: colorArray[x],
        pointRadius: 1,
        pointHoverRadius: 10,
        pointBorderWidth: 0,
      };
    }
    let pcbTempDataSets = [];
    let pcbTempNewArray = Array(pcb_temp_max_len)
      .fill(0)
      .map((row) => new Array(pcbTempArray.length).fill(0));

    for (let k = 0; k < pcbTempArray.length; k++) {
      for (let j = 0; j < pcbTempArray[k].length; j++) {
        pcbTempNewArray[j][k] = pcbTempArray[k][j];
      }
    }
    for (let x = 0; x < pcbTempNewArray.length; x++) {
      let colorArray = [
        "#08E8DE",
        "#004225",
        "#8B008B",
        "#03C03C",
        "#C04000",
        "#191970",
        "#77DD77",
      ];
      pcbTempDataSets[x] = {
        label: "PCB Temp " + (x + 1) + " (°C)",
        data: pcbTempNewArray[x],
        fill: false,
        borderColor: colorArray[x],
        backgroundColor: "transparent",
        pointBorderColor: colorArray[x],
        pointRadius: 1,
        pointHoverRadius: 10,
        pointBorderWidth: 0,
      };
    }
    let newTempArray = [];
    newTempArray = [...cellTempDataSets, ...icTempDataSets, ...pcbTempDataSets];
    let yAxisVariableMax;
    let yAxisVariableMin;
    var maxRow = cellVotageNewArray.map(function (row) {
      return Math.max.apply(Math, row);
    });
    yAxisVariableMax = Math.max.apply(null, maxRow);
    var minRow = cellVotageNewArray.map(function (row) {
      return Math.min.apply(Math, row);
    });
    yAxisVariableMin = Math.min.apply(null, minRow);
    if (onClickRange === 0) {
      yAxisVariableMax = yAxisVariableMax + 600;
      yAxisVariableMin = yAxisVariableMin - 600;
    } else if (onClickRange === 1) {
      yAxisVariableMax = yAxisVariableMax + 800;
      yAxisVariableMin = yAxisVariableMin - 800;
    } else if (onClickRange === 2) {
      yAxisVariableMax = yAxisVariableMax + 1000;
      yAxisVariableMin = yAxisVariableMin - 1000;
    } else if (onClickRange === 3) {
      yAxisVariableMax = yAxisVariableMax + 1600;
      yAxisVariableMin = yAxisVariableMin - 1600;
    } else if (onClickRange === 4) {
      yAxisVariableMax = yAxisVariableMax + 2000;
      yAxisVariableMin = yAxisVariableMin - 2000;
    }
    let yAxisTempVariableMax;
    let yAxisTempVariableMin;
    let tempArray = [
      ...cellTempNewArray,
      ...pcbTempNewArray,
      ...icTempNewArray,
    ];
    var maxTempRow = tempArray.map(function (row) {
      return Math.max.apply(Math, row);
    });
    yAxisTempVariableMax = Math.max.apply(null, maxTempRow);
    var minTempRow = tempArray.map(function (row) {
      return Math.min.apply(Math, row);
    });
    yAxisTempVariableMin = Math.min.apply(null, minTempRow);
    if (onClickTempRange === 0) {
      yAxisTempVariableMax = yAxisTempVariableMax + 2;
      yAxisTempVariableMin = yAxisTempVariableMin - 2;
    } else if (onClickTempRange === 1) {
      yAxisTempVariableMax = yAxisTempVariableMax + 4;
      yAxisTempVariableMin = yAxisTempVariableMin - 4;
    } else if (onClickTempRange === 2) {
      yAxisTempVariableMax = yAxisTempVariableMax + 8;
      yAxisTempVariableMin = yAxisTempVariableMin - 8;
    } else if (onClickTempRange === 3) {
      yAxisTempVariableMax = yAxisTempVariableMax + 16;
      yAxisTempVariableMin = yAxisTempVariableMin - 16;
    } else if (onClickTempRange === 4) {
      yAxisTempVariableMax = yAxisTempVariableMax + 32;
      yAxisTempVariableMin = yAxisTempVariableMin - 32;
    }

    const cellVoltageChart = {
      data: {
        labels: timeLabel,
        datasets: cellVoltageDataSets,
      },
      options: {
        tooltips: {
          xAlign: "center",
          position: "average",
          mode: "label",
          // multiKeyBackground: "rgba(256,256,256,0.7)",
          // backgroundColor: "rgba(256,256,256,0.7)",
          // bodyFontColor: "#000",
          intersect: true,
          callbacks: {
            label: function (context) {
              //   if(context.datasetIndex === 0)
              //   someArray = []
              // if(context.datasetIndex===cellVotageNewArray.length+9)
              // console.log('this has happened')
              someArray[context.datasetIndex] = context.value
              // console.log('this value',someArray)
              setTooltipArray(someArray.toString())
            },
          },
        },
        elements: {
          line: {
            tension: 0.3,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                max: yAxisVariableMax,
                min: yAxisVariableMin,
              },
            },
          ],
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 10,
            fontSize: 10,
          },
        },
      },
    };

    const cellTempChart = {
      data: {
        labels: timeLabel,
        datasets: newTempArray,
      },
      options: {
        tooltips: {
          yAxis: "center",
        },
        elements: {
          line: {
            tension: 0.3,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                max: yAxisTempVariableMax,
                min: yAxisTempVariableMin,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
      },
    };


    const HeatSinkTempChargeDischargeChart = {
      data: {
        labels: timeLabel,
        datasets: [
          {
            label: "Heat Sink Temp",
            data: PackVoltageData,
            fill: false,
            borderColor: "blue",
            backgroundColor: "transparent",
            pointBorderColor: "blue",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-1",
          },
          {
            label: "Charge Discharge",
            data: PackCurrentData,
            fill: false,
            borderColor: "#f00",
            backgroundColor: "transparent",
            pointBorderColor: "#f00",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-2",
          },
          {
            label: "third",
            data: SOHData,
            fill: false,
            borderColor: "#ff0",
            backgroundColor: "transparent",
            pointBorderColor: "#ff0",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-3",
          },
        ],
      },
      options: {
        elements: {
          line: {
            tension: 0.3,
          },
        },
        legend: {
          display: true,
          position: "top",
        },
      },
    };

    const RealTimeChargeDischargeChart = {
      data: {
        labels: timeLabel,
        datasets: [
          {
            label: "Pack Current",
            data: PackCurrentData,
            fill: false,
            borderColor: "#08bf11",
            pointBorderColor: "#08bf11",
            pointRadius: 1,
            pointHoverRadius: 3,
          },
          {
            label: "Average Pack Current",
            data: AveragePackCurrentData,
            fill: false,
            borderColor: "#DE5D83",
            pointBorderColor: "#DE5D83",
            pointRadius: 1,
            pointHoverRadius: 3,
          },
          {
            label: "Max Pack Current",
            data: MaxPackCurrentData,
            fill: false,
            borderColor: "#0099cc",
            pointBorderColor: "#0099cc",
            pointRadius: 1,
            pointHoverRadius: 3,
          },
          {
            label: "Over Charge Current",
            data: overChargeCurrent,
            fill: false,
            borderColor: "#c9d4cc",
            pointBorderColor: "#c9d4cc",
            pointRadius: 1,
            pointHoverRadius: 3,
            borderDash: [1, 1],
          },
          {
            label: "Over Discharge Current",
            data: overDisCurrent,
            fill: false,
            borderColor: "#c9d4cc",
            pointBorderColor: "#c9d4cc",
            pointRadius: 1,
            pointHoverRadius: 3,
            borderDash: [1, 1],
          },
          {
            label: "Hard Charge Over Current",
            data: hardChgOverCurrent,
            fill: false,
            borderColor: "#878a88",
            pointBorderColor: "#878a88",
            pointRadius: 1,
            pointHoverRadius: 3,
            borderDash: [1, 1],
          },
          {
            label: "Hard Discharge Over Current",
            data: hardDsgOverCurrent,
            fill: false,
            borderColor: "#878a88",
            pointBorderColor: "#878a88",
            pointRadius: 1,
            pointHoverRadius: 3,
            borderDash: [1, 1],
          },
        ],
      },
      options: {
        tooltips: {
          mode: "label",
        },
        elements: {
          line: {
            tension: 0.3,
          },
        },
        scales: {
          yAxes: [
            // {
            //   ticks: {
            //     beginAtZero: false,
            //     max: 70,
            //     min: -70,
            //   },
            // },
          ],
        },
        legend: {
          display: true,
          position: "top",
        },
      },
    };
    const PackVoltageChart = {
      data: {
        labels: timeLabel,
        datasets: [
          {
            label: "Pack Voltage",
            data: PackVoltageData,
            fill: false,
            borderColor: "#0f0",
            backgroundColor: "transparent",
            pointBorderColor: "#0f0",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
          },
          {
            label: "Pack Over Voltage",
            data: packOverVoltage,
            fill: false,
            borderColor: "#c9d4cc",
            backgroundColor: "transparent",
            pointBorderColor: "#c9d4cc",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            borderDash: [1, 1],
          },
          {
            label: "Pack Under Voltage",
            data: packUnderVoltage,
            fill: false,
            borderColor: "#c9d4cc",
            backgroundColor: "transparent",
            pointBorderColor: "#c9d4cc",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            borderDash: [1, 1],
          },
          {
            label: "Hard Pack Over Voltage",
            data: hardPackOverVoltage,
            fill: false,
            borderColor: "#878a88",
            backgroundColor: "transparent",
            pointBorderColor: "#878a88",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            borderDash: [1, 1],
          },
          {
            label: "Hard Pack Under Voltage",
            data: hardPackUnderVoltage,
            fill: false,
            borderColor: "#878a88",
            backgroundColor: "transparent",
            pointBorderColor: "#878a88",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            borderDash: [1, 1],
          },
        ],
      },
      options: {
        elements: {
          line: {
            tension: 0.3,
          },
        },
        scales: {
          yAxes: [
            // {
            //   ticks: {
            //     beginAtZero: false,
            //     max: 100,
            //     min: 30,
            //   },
            // },
          ],
        },
        legend: {
          display: true,
          position: "top",
        },
      },
    };
    const SOHChart = {
      data: {
        labels: timeLabel,
        datasets: [
          {
            label: "State of Health",
            data: SOHData,
            fill: false,
            borderColor: "#0ff",
            backgroundColor: "transparent",
            pointBorderColor: "#0ff",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
          },
        ],
      },
      options: {
        elements: {
          line: {
            tension: 0.3,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                max: 100,
                min: 0,
              },
            },
          ],
        },
        legend: {
          display: true,
          position: "top",
        },
      },
    };

    const PowerChart = {
      data: {
        labels: timeLabel,
        datasets: [
          {
            label: "Power",
            data: PackPowerData,
            fill: false,
            borderColor: "#00f",
            backgroundColor: "transparent",
            pointBorderColor: "#00f",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
          },
        ],
      },
      options: {
        elements: {
          line: {
            tension: 0.3,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                max: 20,
                min: -20,
              },
            },
          ],
        },
        legend: {
          display: true,
          position: "top",
        },
      },
    };

    const heatSinkTempChargeDischargeOptions = {
      legend: {
        labels: {
          fontColor: "blue",
          fontSize: 18,
        },
      },
      scales: {
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            ticks: {
              fontColor: "green",
              fontSize: 12,
            },
          },
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-2",
            gridLines: {
              drawOnArea: false,
            },
          },
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-3",
            gridLines: {
              drawOnArea: false,
            },
          },
        ],
      },
    };
    const StateOfChargeChart = {
      data: {
        labels: timeLabel,
        datasets: [
          {
            label: "State of Charge",
            data: StateOfChargeData,
            fill: false,
            borderColor: "pink",
            backgroundColor: "transparent",
            pointBorderColor: "pink",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
          },
          {
            label: "Reserve Capacity S1",
            data: reserveCapacityS1,
            fill: false,
            borderColor: "#c9d4cc",
            backgroundColor: "transparent",
            pointBorderColor: "c9d4cc",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            borderDash: [1, 1],
          },
          {
            label: "Reserve Capacity S2",
            data: reserveCapacityS2,
            fill: false,
            borderColor: "#878a88",
            backgroundColor: "transparent",
            pointBorderColor: "#878a88",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            borderDash: [1, 1],
          },
        ],
      },
      options: {
        elements: {
          line: {
            tension: 0.3,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                max: 100,
                min: 0,
              },
            },
          ],
        },
        legend: {
          display: true,
          position: "top",
        },
      },
    };
    const BatteryHealthPredictionChart = {
      data: {
        labels: ["0", "1000", "2000", "3000"],
        datasets: [
          {
            label: "Battery Health Prediction",
            data: ["100", "97", "50", "30", "0"],
            fill: false,
            borderColor: "pink",
            backgroundColor: "transparent",
            pointBorderColor: "pink",
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
          },
        ],
      },
      options: {
        elements: {
          line: {
            tension: 0.3,
          },
        },
        legend: {
          display: true,
          position: "top",
        },
      },
    };
    const buttonBasics = {
      borderWidth: 0,
      borderRadius: "20px",
      backgroundColor: "#B6BDBB",
      color: "white",
      padding: "5px 15px 5px 15px",
      margin: "5px",
      fontWeight: "bold",
    };
    const cellVoltageOptionsForMultiGraph = [];
    for (let i = 0; i < cellVotageNewArray.length; i++) {
      cellVoltageOptionsForMultiGraph[i] = {
        value: "Cell " + (1 + i),
        label: "Cell " + (1 + i),
      };
    }
    const cellTempOptionsForMultiGraph = [];
    for (let i = 0; i < cellTempNewArray.length; i++) {
      cellTempOptionsForMultiGraph[i] = {
        value: "Cell Temperature " + (1 + i),
        label: "Cell Temperature " + (1 + i),
      };
    }
    const pcbTempOptionsForMultiGraph = [];
    for (let i = 0; i < pcbTempNewArray.length; i++) {
      pcbTempOptionsForMultiGraph[i] = {
        value: "PCB Temperature " + (1 + i),
        label: "PCB Temperature " + (1 + i),
      };
    }
    const icTempOptionsForMultiGraph = [];
    for (let i = 0; i < icTempNewArray.length; i++) {
      icTempOptionsForMultiGraph[i] = {
        value: "IC Temperature " + (1 + i),
        label: "IC Temperature " + (1 + i),
      };
    }
    const valueDataOptions = [
      { value: "PackVoltageData", label: "Pack Voltage Data" },
      { value: "PackCurrentData", label: "Pack Current Data" },
      { value: "SOHData", label: "SOH Data" },
      { value: "StateOfChargeData", label: "SOC Data" },
      { value: "PackPowerData", label: "Pack Power Data" },
    ];
    const multiGraphOptions = valueDataOptions.concat(
      cellVoltageOptionsForMultiGraph,
      cellTempOptionsForMultiGraph,
      pcbTempOptionsForMultiGraph,
      icTempOptionsForMultiGraph
    );
    let datasetArr = [];
    let yAxesArr = [];
    let graphData;
const trueColors = [
  "#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF",
  "#00FFFF", "#800000", "#808000", "#008000", "#800080",
  "#008080", "#000080", "#FFA500", "#A52A2A", "#8B0000",
  "#FF4500", "#2E8B57", "#ADFF2F", "#7FFF00", "#7CFC00",
  "#00FA9A", "#00FF7F", "#3CB371", "#20B2AA", "#48D1CC",
  "#40E0D0", "#87CEEB", "#4682B4", "#1E90FF", "#00008B",
  "#8A2BE2", "#9400D3", "#9932CC", "#8B008B", "#BA55D3",
  "#DA70D6", "#DDA0DD", "#EE82EE", "#D8BFD8", "#D2691E",
  "#FF7F50", "#FF6347", "#FF8C00", "#FFD700", "#B8860B",
  "#DAA520", "#BDB76B", "#F0E68C", "#EEE8AA", "#F5DEB3",
  "#FFE4B5", "#FFA07A", "#FFB6C1", "#FF69B4", "#FF1493",
  "#C71585", "#DB7093", "#FF00FF", "#FF33CC", "#FF66FF",
  "#FF99FF", "#CC99FF", "#9966CC", "#663399", "#660066",
  "#800000", "#800080", "#8B0000", "#B22222", "#A52A2A",
  "#CD5C5C", "#DC143C", "#FF0000", "#FF4500", "#FF6347",
  "#FF7F50", "#FFA07A", "#FA8072", "#E9967A", "#F08080",
  "#CD853F", "#D2691E", "#8B4513", "#A0522D", "#A52A2A",
  "#B22222", "#BC8F8F", "#D3D3D3", "#D8BFD8", "#FF00FF",
  "#FF00FF", "#C71585", "#DB7093", "#FF1493", "#FF69B4",
  "#FFB6C1", "#FFC0CB", "#FF33CC", "#FF66FF", "#FF99FF"
]
    if (filterData) {
      for (let i = 0; i < filterData.length; i++) {
        if (filterData[i].value === "PackVoltageData") {
          datasetArr[i] = {
            label: "Pack Voltage",
            data: PackVoltageData,
            fill: false,
            borderColor: trueColors[0],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[0],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-1",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            ticks: {
              fontColor: trueColors[0],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "PackCurrentData") {
          datasetArr[i] = {
            label: "Pack Current",
            data: PackCurrentData,
            fill: false,
            borderColor: trueColors[1],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[1],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-2",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-2",
            ticks: {
              fontColor: trueColors[1],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "SOHData") {
          datasetArr[i] = {
            label: "SOH",
            data: SOHData,
            fill: false,
            borderColor: trueColors[2],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[2],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-3",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-3",
            ticks: {
              fontColor: trueColors[2],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "StateOfChargeData") {
          datasetArr[i] = {
            label: "SOC",
            data: StateOfChargeData,
            fill: false,
            borderColor: trueColors[3],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[3],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-3",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-3",
            ticks: {
              fontColor: trueColors[3],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "PackPowerData") {
          datasetArr[i] = {
            label: "Pack Power",
            data: PackPowerData,
            fill: false,
            borderColor: trueColors[4],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[4],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-4",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-4",
            ticks: {
              fontColor: trueColors[4],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 1") {
          datasetArr[i] = {
            label: "Cell 1",
            data: cellVotageNewArray[0],
            fill: false,
            borderColor:  trueColors[5],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[5],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[5],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 2") {
          datasetArr[i] = {
            label: "Cell 2",
            data: cellVotageNewArray[1],
            fill: false,
            borderColor:  trueColors[6],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[6],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[6],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 3") {
          datasetArr[i] = {
            label: "Cell 3",
            data: cellVotageNewArray[2],
            fill: false,
            borderColor:  trueColors[7],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[7],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[7],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 4") {
          datasetArr[i] = {
            label: "Cell 4",
            data: cellVotageNewArray[3],
            fill: false,
            borderColor:  trueColors[8],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[8],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[8],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 5") {
          datasetArr[i] = {
            label: "Cell 5",
            data: cellVotageNewArray[4],
            fill: false,
            borderColor:  trueColors[9],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[9],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[9],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 6") {
          datasetArr[i] = {
            label: "Cell 6",
            data: cellVotageNewArray[5],
            fill: false,
            borderColor:  trueColors[10],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[10],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[10],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 7") {
          datasetArr[i] = {
            label: "Cell 7",
            data: cellVotageNewArray[6],
            fill: false,
            borderColor:  trueColors[11],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[11],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[11],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 8") {
          datasetArr[i] = {
            label: "Cell 8",
            data: cellVotageNewArray[7],
            fill: false,
            borderColor:  trueColors[12],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[12],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[12],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 9") {
          datasetArr[i] = {
            label: "Cell 9",
            data: cellVotageNewArray[8],
            fill: false,
            borderColor:  trueColors[13],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[13],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[13],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 10") {
          datasetArr[i] = {
            label: "Cell 10",
            data: cellVotageNewArray[9],
            fill: false,
            borderColor:  trueColors[14],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[14],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[14],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 11") {
          datasetArr[i] = {
            label: "Cell 11",
            data: cellVotageNewArray[10],
            fill: false,
            borderColor:  trueColors[15],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[15],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[15],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 12") {
          datasetArr[i] = {
            label: "Cell 12",
            data: cellVotageNewArray[11],
            fill: false,
            borderColor:  trueColors[16],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[16],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[16],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 13") {
          datasetArr[i] = {
            label: "Cell 13",
            data: cellVotageNewArray[12],
            fill: false,
            borderColor:  trueColors[17],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[17],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[17],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 14") {
          datasetArr[i] = {
            label: "Cell 14",
            data: cellVotageNewArray[13],
            fill: false,
            borderColor:  trueColors[18],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[18],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[18],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 15") {
          datasetArr[i] = {
            label: "Cell 15",
            data: cellVotageNewArray[14],
            fill: false,
            borderColor:  trueColors[19],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[19],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[19],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 16") {
          datasetArr[i] = {
            label: "Cell 16",
            data: cellVotageNewArray[15],
            fill: false,
            borderColor:  trueColors[20],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[20],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[20],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 17") {
          datasetArr[i] = {
            label: "Cell 17",
            data: cellVotageNewArray[16],
            fill: false,
            borderColor:  trueColors[21],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[21],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[21],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 18") {
          datasetArr[i] = {
            label: "Cell 18",
            data: cellVotageNewArray[17],
            fill: false,
            borderColor:  trueColors[22],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[22],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[22],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 19") {
          datasetArr[i] = {
            label: "Cell 19",
            data: cellVotageNewArray[18],
            fill: false,
            borderColor:  trueColors[23],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[23],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[23],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 20") {
          datasetArr[i] = {
            label: "Cell 20",
            data: cellVotageNewArray[19],
            fill: false,
            borderColor:  trueColors[24],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[24],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[24],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 21") {
          datasetArr[i] = {
            label: "Cell 21",
            data: cellVotageNewArray[20],
            fill: false,
            borderColor:  trueColors[25],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[25],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[25],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 22") {
          datasetArr[i] = {
            label: "Cell 22",
            data: cellVotageNewArray[21],
            fill: false,
            borderColor:  trueColors[26],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[26],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[26],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 23") {
          datasetArr[i] = {
            label: "Cell 23",
            data: cellVotageNewArray[22],
            fill: false,
            borderColor:  trueColors[27],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[27],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[27],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 24") {
          datasetArr[i] = {
            label: "Cell 24",
            data: cellVotageNewArray[23],
            fill: false,
            borderColor:  trueColors[28],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[28],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[28],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 25") {
          datasetArr[i] = {
            label: "Cell 25",
            data: cellVotageNewArray[24],
            fill: false,
            borderColor:  trueColors[29],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[29],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[29],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 26") {
          datasetArr[i] = {
            label: "Cell 26",
            data: cellVotageNewArray[25],
            fill: false,
            borderColor:  trueColors[30],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[30],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[30],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 27") {
          datasetArr[i] = {
            label: "Cell 27",
            data: cellVotageNewArray[26],
            fill: false,
            borderColor:  trueColors[31],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[31],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[31],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 28") {
          datasetArr[i] = {
            label: "Cell 28",
            data: cellVotageNewArray[27],
            fill: false,
            borderColor:  trueColors[32],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[32],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[32],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 29") {
          datasetArr[i] = {
            label: "Cell 29",
            data: cellVotageNewArray[28],
            fill: false,
            borderColor:  trueColors[33],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[33],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[33],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 30") {
          datasetArr[i] = {
            label: "Cell 30",
            data: cellVotageNewArray[29],
            fill: false,
            borderColor:  trueColors[34],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[34],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[34],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 31") {
          datasetArr[i] = {
            label: "Cell 31",
            data: cellVotageNewArray[30],
            fill: false,
            borderColor:  trueColors[35],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[35],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[35],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 32") {
          datasetArr[i] = {
            label: "Cell 32",
            data: cellVotageNewArray[31],
            fill: false,
            borderColor:  trueColors[36],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[36],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[36],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 33") {
          datasetArr[i] = {
            label: "Cell 33",
            data: cellVotageNewArray[32],
            fill: false,
            borderColor:  trueColors[37],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[37],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[37],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 34") {
          datasetArr[i] = {
            label: "Cell 34",
            data: cellVotageNewArray[33],
            fill: false,
            borderColor:  trueColors[38],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[38],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[38],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 35") {
          datasetArr[i] = {
            label: "Cell 35",
            data: cellVotageNewArray[34],
            fill: false,
            borderColor:  trueColors[39],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[39],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[39],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell 36") {
          datasetArr[i] = {
            label: "Cell 36",
            data: cellVotageNewArray[35],
            fill: false,
            borderColor:  trueColors[40],
            backgroundColor: "transparent",
            pointBorderColor:  trueColors[40],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-5",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-5",
            ticks: {
              fontColor:  trueColors[40],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell Temperature 1") {
          datasetArr[i] = {
            label: "Cell Temperature 1",
            data: cellTempNewArray[0],
            fill: false,
            borderColor: trueColors[41],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[41],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[41],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell Temperature 2") {
          datasetArr[i] = {
            label: "Cell Temperature 2",
            data: cellTempNewArray[1],
            fill: false,
            borderColor: trueColors[42],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[42],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[42],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell Temperature 3") {
          datasetArr[i] = {
            label: "Cell Temperature 3",
            data: cellTempNewArray[2],
            fill: false,
            borderColor: trueColors[43],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[43],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[43],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell Temperature 4") {
          datasetArr[i] = {
            label: "Cell Temperature 4",
            data: cellTempNewArray[3],
            fill: false,
            borderColor: trueColors[44],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[44],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[44],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell Temperature 5") {
          datasetArr[i] = {
            label: "Cell Temperature 5",
            data: cellTempNewArray[4],
            fill: false,
            borderColor: trueColors[45],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[45],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[45],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell Temperature 6") {
          datasetArr[i] = {
            label: "Cell Temperature 6",
            data: cellTempNewArray[5],
            fill: false,
            borderColor: trueColors[46],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[46],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[46],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell Temperature 7") {
          datasetArr[i] = {
            label: "Cell Temperature 7",
            data: cellTempNewArray[6],
            fill: false,
            borderColor: trueColors[47],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[47],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[47],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell Temperature 8") {
          datasetArr[i] = {
            label: "Cell Temperature 8",
            data: cellTempNewArray[7],
            fill: false,
            borderColor: trueColors[48],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[48],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[48],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell Temperature 9") {
          datasetArr[i] = {
            label: "Cell Temperature 9",
            data: cellTempNewArray[8],
            fill: false,
            borderColor: trueColors[49],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[49],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[49],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "Cell Temperature 10") {
          datasetArr[i] = {
            label: "Cell Temperature 10",
            data: cellTempNewArray[9],
            fill: false,
            borderColor: trueColors[50],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[50],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[50],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "IC Temperature 1") {
          datasetArr[i] = {
            label: "IC Temperature 1",
            data: icTempNewArray[0],
            fill: false,
            borderColor: trueColors[51],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[51],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[51],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "IC Temperature 2") {
          datasetArr[i] = {
            label: "IC Temperature 2",
            data: icTempArray[1],
            fill: false,
            borderColor: trueColors[52],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[52],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[52],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "IC Temperature 3") {
          datasetArr[i] = {
            label: "IC Temperature 3",
            data: icTempArray[2],
            fill: false,
            borderColor: trueColors[53],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[53],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[53],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "IC Temperature 4") {
          datasetArr[i] = {
            label: "IC Temperature 4",
            data: icTempArray[3],
            fill: false,
            borderColor: trueColors[54],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[54],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[54],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "PCB Temperature 1") {
          datasetArr[i] = {
            label: "PCB Temperature 1",
            data: pcbTempNewArray[0],
            fill: false,
            borderColor: trueColors[55],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[55],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[55],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "PCB Temperature 2") {
          datasetArr[i] = {
            label: "PCB Temperature 2",
            data: pcbTempNewArray[1],
            fill: false,
            borderColor: trueColors[56],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[56],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[56],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "PCB Temperature 3") {
          datasetArr[i] = {
            label: "PCB Temperature 3",
            data: pcbTempNewArray[2],
            fill: false,
            borderColor: trueColors[57],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[57],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[57],
              fontSize: 10,
            },
          };
        } else if (filterData[i].value === "PCB Temperature 4") {
          datasetArr[i] = {
            label: "PCB Temperature 4",
            data: pcbTempNewArray[3],
            fill: false,
            borderColor: trueColors[58],
            backgroundColor: "transparent",
            pointBorderColor: trueColors[58],
            pointRadius: 1,
            pointHoverRadius: 10,
            pointBorderWidth: 0,
            yAxisID: "y-axis-6",
          };
          yAxesArr[i] = {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-6",
            ticks: {
              fontColor: trueColors[58],
              fontSize: 10,
            },
          };
        }
      }
      graphData = {
        data: {
          labels: timeLabel,
          datasets: datasetArr,
        },
        options: {
          tooltips: {
            mode: "label",
          },
          elements: {
            line: {
              tension: 0.3,
            },
          },
          legend: {
            display: true,
            position: "top",
          },
          scales: {
            yAxes: yAxesArr,
          },
        },
      };
    }
    let showToolTipData
    
      if (valueData.length > 0) {
        if (tootltipArray) {
          let newArray = tootltipArray.split(',')
          let someOtherArray = []
          for (let index = 0; index < cellVoltageArray[0].length; index++) {
            someOtherArray[index] = 'Cell ' + (index + 1) + ' : ' + newArray[index] + '(mV)'
          }
          someOtherArray[cellVoltageArray[0].length] = 'Cell Over Voltage : ' + newArray[cellVoltageArray[0].length] + '(mV)'
          someOtherArray[cellVoltageArray[0].length + 1] = 'Cell Under Voltage : ' + newArray[cellVoltageArray[0].length + 1] + '(mV)'
          someOtherArray[cellVoltageArray[0].length + 2] = 'Hard Cell Over Voltage : ' + newArray[cellVoltageArray[0].length + 2] + '(mV)'
          someOtherArray[cellVoltageArray[0].length + 3] = 'Hard Cell Under Voltage : ' + newArray[cellVoltageArray[0].length + 3] + '(mV)'
          someOtherArray[cellVoltageArray[0].length + 4] = 'Cell Voltage Difference : ' + newArray[cellVoltageArray[0].length + 4] + '(mV)'
          someOtherArray[cellVoltageArray[0].length + 5] = 'Pack Current : ' + newArray[cellVoltageArray[0].length + 5] + '(A)'
          someOtherArray[cellVoltageArray[0].length + 6] = 'PDU Temperature[max] : ' + newArray[cellVoltageArray[0].length + 6] + '(°C)'
          someOtherArray[cellVoltageArray[0].length + 7] = 'Cell Temperature[max] : ' + newArray[cellVoltageArray[0].length + 7] + '(°C)'
          someOtherArray[cellVoltageArray[0].length + 8] = 'SOC : ' + newArray[cellVoltageArray[0].length + 8] + '(%)'
          someOtherArray[cellVoltageArray[0].length + 9] = 'Pack Voltage : ' + newArray[cellVoltageArray[0].length + 9] + '(V)'

          showToolTipData = <div>
            {someOtherArray.map((val, i) => <div>{val}</div>)}
          </div>
        }
        return (
          <div>
            <div>
              <div>
                No Of Samples:
                <b style={{ margin: "5px" }}>
                  <input
                    onChange={(e) => {
                      {
                        if (e.target.value > 500) alert('Max limit 500')
                        else
                          setNoOfSamples(parseInt(e.target.value))
                      }
                    }}
                    value={noOfSamples}
                    placeholder="Max 500"
                    type="number"
                  >
                  </input>
                </b>
                <button
                  onClick={changeNoOfSample}
                  // disabled={disablingButton ? "disabled" : null}
                  style={{ marginRight: "50px" }}
                >
                  Set
                </button>
                <input
                  type="date"
                  onChange={(event) => getDateTime(event.target.value)}
                ></input>
                <button
                  style={buttonBasics}
                  onClick={fetchBmsValues}
                >
                  Submit
                </button>
                <input
                  type="time"
                  onChange={(event) => setTime(event.target.value)}
                ></input>
                <button
                  style={buttonBasics}
                  onClick={() => getTime(time)}
                >
                  Submit
                </button>
              </div>
            </div>
            <Nav className="justify-content-center" role="tablist" tabs>
              <NavItem>
                <NavLink
                  className={iconPills === "1" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("1");
                  }}
                >
                  Cell Voltages
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={iconPills === "2" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("2");
                  }}
                >
                  Pack Voltage
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={iconPills === "3" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("3");
                  }}
                >
                  Pack Current
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={iconPills === "4" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("4");
                  }}
                >
                  Power
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={iconPills === "5" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("5");
                  }}
                >
                  Temperature
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={iconPills === "6" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("6");
                  }}
                >
                  SOC
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={iconPills === "7" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("7");
                  }}
                >
                  SOH
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={iconPills === "11" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("11");
                  }}
                >
                  Multi-Graph
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="text-center" activeTab={"iconPills" + iconPills}>
              <TabPane tabId="iconPills1">
                <strong>Cell Voltage (mV)</strong>
                <Line
                  data={cellVoltageChart.data}
                  options={cellVoltageChart.options}
                  id="tooltip-sidebar"
                />
                <UncontrolledTooltip placement="right" target="tooltip-sidebar">
                  {showToolTipData}
                </UncontrolledTooltip>
                <button
                  style={buttonBasics}
                  onClick={() => previousClicked(valueData[0].tms)}
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                {clickLoader ? (
                  <strong style={{ margin: "0 15px", cursor: "pointer" }}>
                    Loading...
                  </strong>
                ) : (<strong
                  style={{ margin: "0 15px", cursor: "pointer" }}
                  onClick={recenter}
                >
                  Sample Time (s)
                </strong>)}
                <button
                  style={buttonBasics}
                  onClick={() =>
                    nextClicked(
                      valueData[valueData.length - 1].tms
                    )
                  }
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
                <div>
                  <UncontrolledTooltip placement="top" target="zoomin-button">
                    Zoom Out
                  </UncontrolledTooltip>
                  <UncontrolledTooltip placement="top" target="zoomout-button">
                    Zoom In
                  </UncontrolledTooltip>
                  <button
                    style={buttonBasics}
                    onClick={incrementFunction}
                    id="zoomin-button"
                  >
                    <i class="fas fa-search-minus"></i>
                  </button>
                  <button
                    style={buttonBasics}
                    onClick={decrementFunction}
                    id="zoomout-button"
                  >
                    <i class="fas fa-search-plus"></i>
                  </button>
                </div>
              </TabPane>
              <TabPane tabId="iconPills2">
                <strong>Pack Voltage (V)</strong>
                <Line
                  data={PackVoltageChart.data}
                  options={PackVoltageChart.options}
                />
                <button
                  style={buttonBasics}
                  onClick={() => previousClicked(valueData[0].tms)}
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                {clickLoader ? (
                  <strong style={{ margin: "0 15px", cursor: "pointer" }}>
                    Loading...
                  </strong>
                ) : (<strong
                  style={{ margin: "0 15px", cursor: "pointer" }}
                  onClick={recenter}
                >
                  Sample Time (s)
                </strong>)}
                <button
                  style={buttonBasics}
                  onClick={() =>
                    nextClicked(
                      valueData[valueData.length - 1].tms
                    )
                  }
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </TabPane>
              <TabPane tabId="iconPills3">
                <strong>Pack Current (A)</strong>
                <Line
                  data={RealTimeChargeDischargeChart.data}
                  options={RealTimeChargeDischargeChart.options}
                />
                <button
                  style={buttonBasics}
                  onClick={() => previousClicked(valueData[0].tms)}
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                {clickLoader ? (
                  <strong style={{ margin: "0 15px", cursor: "pointer" }}>
                    Loading...
                  </strong>
                ) : (<strong
                  style={{ margin: "0 15px", cursor: "pointer" }}
                  onClick={recenter}
                >
                  Sample Time (s)
                </strong>)}
                <button
                  style={buttonBasics}
                  onClick={() =>
                    nextClicked(
                      valueData[valueData.length - 1].tms
                    )
                  }
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </TabPane>
              <TabPane tabId="iconPills4">
                <strong>Pack Power (kW)</strong>
                <Line data={PowerChart.data} options={PowerChart.options} />
                <button
                  style={buttonBasics}
                  onClick={() => previousClicked(valueData[0].tms)}
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                {clickLoader ? (
                  <strong style={{ margin: "0 15px", cursor: "pointer" }}>
                    Loading...
                  </strong>
                ) : (<strong
                  style={{ margin: "0 15px", cursor: "pointer" }}
                  onClick={recenter}
                >
                  Sample Time (s)
                </strong>)}
                <button
                  style={buttonBasics}
                  onClick={() =>
                    nextClicked(
                      valueData[valueData.length - 1].tms
                    )
                  }
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </TabPane>
              <TabPane tabId="iconPills5">
                <strong>Temperature (°C)</strong>
                <Line data={cellTempChart.data} options={cellTempChart.options} />

                <button
                  style={buttonBasics}
                  onClick={() => previousClicked(valueData[0].tms)}
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                {clickLoader ? (
                  <strong style={{ margin: "0 15px", cursor: "pointer" }}>
                    Loading...
                  </strong>
                ) : (<strong
                  style={{ margin: "0 15px", cursor: "pointer" }}
                  onClick={recenter}
                >
                  Sample Time (s)
                </strong>)}
                <button
                  style={buttonBasics}
                  onClick={() =>
                    nextClicked(
                      valueData[valueData.length - 1].tms
                    )
                  }
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
                <div>
                  <UncontrolledTooltip placement="top" target="zoomin-button">
                    Zoom Out
                  </UncontrolledTooltip>
                  <UncontrolledTooltip placement="top" target="zoomout-button">
                    Zoom In
                  </UncontrolledTooltip>
                  <button
                    style={buttonBasics}
                    onClick={incrementTempFunction}
                    id="zoomin-button"
                  >
                    <i class="fas fa-search-minus"></i>
                  </button>
                  <button
                    style={buttonBasics}
                    onClick={decrementTempFunction}
                    id="zoomout-button"
                  >
                    <i class="fas fa-search-plus"></i>
                  </button>
                </div>
              </TabPane>
              <TabPane tabId="iconPills6">
                <strong>State of Charge (%)</strong>
                <Line
                  data={StateOfChargeChart.data}
                  options={StateOfChargeChart.options}
                />
                <button
                  style={buttonBasics}
                  onClick={() => previousClicked(valueData[0].tms)}
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                {clickLoader ? (
                  <strong style={{ margin: "0 15px", cursor: "pointer" }}>
                    Loading...
                  </strong>
                ) : (<strong
                  style={{ margin: "0 15px", cursor: "pointer" }}
                  onClick={recenter}
                >
                  Sample Time (s)
                </strong>)}
                <button
                  style={buttonBasics}
                  onClick={() =>
                    nextClicked(
                      valueData[valueData.length - 1].tms
                    )
                  }
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </TabPane>
              <TabPane tabId="iconPills7">
                <strong>State of Health (%)</strong>
                <Line data={SOHChart.data} options={SOHChart.options} />

                <button
                  style={buttonBasics}
                  onClick={() => previousClicked(valueData[0].tms)}
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                {clickLoader ? (
                  <strong style={{ margin: "0 15px", cursor: "pointer" }}>
                    Loading...
                  </strong>
                ) : (<strong
                  style={{ margin: "0 15px", cursor: "pointer" }}
                  onClick={recenter}
                >
                  Sample Time (s)
                </strong>)}
                <button
                  style={buttonBasics}
                  onClick={() =>
                    nextClicked(
                      valueData[valueData.length - 1].tms
                    )
                  }
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </TabPane>

              <TabPane tabId="iconPills11">
                <strong>Select multiple parameters to compare</strong>
                <Row>
                  <Col>
                    <ReactSelect
                      isMulti
                      options={multiGraphOptions}
                      className="basic-multi-select"
                      classNamePrefix="select bms parameters"
                      onChange={checkBoxData}
                    />
                  </Col>
                </Row>
                {filterData ? (
                  <>
                    <Line data={graphData.data} options={graphData.options} />
                    <button
                      style={buttonBasics}
                      onClick={() => previousClicked(valueData[0].tms)}
                    >
                      <i class="fas fa-chevron-left"></i>
                    </button>

                    {clickLoader ? (
                      <strong style={{ margin: "0 15px", cursor: "pointer" }}>
                        Loading...
                      </strong>
                    ) : (<strong
                      style={{ margin: "0 15px", cursor: "pointer" }}
                      onClick={recenter}
                    >
                      Sample Time (s)
                    </strong>)}
                    <button
                      style={buttonBasics}
                      onClick={() =>
                        nextClicked(
                          valueData[valueData.length - 1].tms
                        )
                      }
                    >
                      <i class="fas fa-chevron-right"></i>
                    </button>
                  </>
                ) : (
                  ""
                )}
              </TabPane>
            </TabContent>
          </div>
        );
      }
      else return <div>Previous BMS data not found</div>
    
  }} else {
    return   <div>
        Loading...</div>
  }
}
export default Analytics;
